.rep-sub-title {
  font-size: 14px;
  font-weight: 500;
}

.module-radio span.ant-radio+span, .range-radio span.ant-radio+span {
  font-size: 13px;
  font-weight: 500;
}

.download-btn.custom-button:not(:disabled) {
  min-width: 180px;
}

@media screen and (max-width: 991px) {
  .module-radio {
    justify-content: flex-start;
    gap: 5px;
  }

  .module-radio > label {
    flex: 0 0 180px;
  }
}
