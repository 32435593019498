.dash-row {
  border: 1px solid var(--primaryblue);
  border-radius: 15px;
}

.dash-inner-title {
  background-color: var(--primaryblue);
  color: white;
  font-size: clamp(17px, 1.5vw, 1.5vw);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px 0 0 15px;
  text-transform: uppercase;
  width: 200px;
  padding: 15px;
}

.ant-col.dash-inner-wrapper {
  min-width: 120px;
  max-width: 150px;
}

.data-wrapper {
  border: 1px solid var(--primaryblue);
  border-radius: 15px;
  text-align: center;
  margin: 15px 0;
}

.title {
  background-color: var(--primaryblue);
  color: white;
  padding: 6px;
  border-radius: 13px 13px 0 0;
  margin-bottom: 0;
  font-size: 12px;
}

.data {
  padding: 10px;
  margin-bottom: 0;
  font-size: 22px;
  font-weight: 700;
  background-color: white;
  border-radius: inherit;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.neg-ml-dash {
  margin-left: -2.5%;
}