.search-icon svg {
  color: white;
  background: var(--primaryblue);
  height: var(--inputheight);
  width: var(--inputheight);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  padding: 6px;
}