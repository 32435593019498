/* .custom-table.buildup-table .ant-table {
  max-height: calc(var(--content-height) - 98px);
} */
.ant-checkbox-wrapper+.ant-checkbox-wrapper {
  margin-left: 0 !important;
}

.info-card.cc-card.ant-card .ant-card-body {
  overflow: auto;
}

.info-title-row>[class*="col-"] {
  border-bottom: 2px solid #696969;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.allowed-uld .info-title {
  border-bottom: 2px solid #696969;
  padding-bottom: 6px;
  margin-bottom: 10px;
  display: block;
}

.info-card.cc-card.ant-card .info-data {
  line-height: normal !important;
  font-size: 12px;
  font-weight: 600;
}

.info-card.cc-card.ant-card .info-title {
  font-size: 11px;

}

/* .info-card.cc-card.ant-card .ant-col,
.info-card.ant-card .ant-col {
  min-width: 80px;
} */

.info-card.cc-card.ant-card .ant-card-body {
  flex-direction: column;
}


.allowed-uld .info-data {
  word-spacing: 18px;
}

.udl-pl {
  padding-left: 4rem !important;
}

.pa-badge {
  background-color: var(--primaryblue);
  color: #fff;
  border-radius: 4px;
}

.selected td .pa-badge {
  background-color: white;
  color: var(--primaryblue);
}

.capsule {
  font-weight: 400;
  font-style: normal;
  font-size: 10px;
  letter-spacing: normal;
  /* border: 1px solid var(--primaryblue); */
  --cap_br: 5px;
  border-radius: var(--cap_br);
}

.capsule>span {
  padding: .2rem .4rem;
  border: 1px solid var(--primaryblue);
  text-align: center;
  display: inline-block;
  height: 100%;
  min-width: 29px;
  font-weight: 600;
  line-height: normal;
}

.capsule>span:first-child {
  border-radius: var(--cap_br) 0 0 var(--cap_br);
}

.capsule>span:last-child {
  background-color: var(--primaryblue);
  color: #fff;
  border-radius: 0 var(--cap_br) var(--cap_br) 0;
}

.place-center {
  display: grid !important;
  place-content: center;
}

.ant-tag.primary-tag {
  background: #fff;
  color: var(--primaryblue);
  border-radius: 5px;
  border-color: var(--tb-border);
  min-height: var(--inputheight);
  display: flex;
  align-items: center;
}

.border-primary .ant-select-selector,
.border-primary .ant-select-selector:hover,
.border-primary .ant-select-selector:focus,
.border-primary .ant-select-selector:active {
  border-color: var(--primaryblue) !important;
}

.red-cc {
  background: var(--danger) !important;
  color: #fff !important;
  border-color: transparent !important;
}

.green-cc {
  background: var(--success) !important;
  color: #fff !important;
  border-color: transparent !important;
}

.amber-cc {
  background: var(--warning) !important;
  color: #fff !important;
  border-color: transparent !important;
}

.repl-btn {
  min-height: var(--inputheight);
  padding: 2px !important;
  height: auto !important;
}

.repl-btn>span {
  white-space: break-spaces;
  line-height: 1.1;
}

.cargo-cap-card .info-title {
  padding-bottom: 10px;
  color: #939393;
  font-weight: 700;
  display: block;
}

.cargo-cap-card .info-data {
  margin-bottom: 0;
  font-weight: 700;
  line-height: 1;
  color: white;
}

.cargo-cap-card.ant-card {
  background-color: var(--primeblue_op);
  border-radius: 15px;
  border: none;
}

.uld-table {
  min-width: 30px !important;
}

.custom-table.ex-inner-tbl {
  border-bottom: 2px solid #b3b2b2;
  margin-top: -10px;
}

.custom-table.ex-inner-tbl table {
  padding-right: 0;
}

.ex-tabl .ant-table-expanded-row>.ant-table-cell,
.ex-tabl .ant-table-expanded-row>.ant-table-cell:hover,
.ex-tabl .ant-table-expanded-row>.ant-table-cell:focus {
  border: none;
  background-color: transparent;
  padding: 0;
}

.ex-tabl .ant-table-expanded-row:hover {
  outline: none;
}

.ex-tabl .ex-inner-tbl .ant-table-tbody>tr>td>.ant-table-expanded-row-fixed>.ant-table-wrapper:only-child .ant-table,
.ex-tabl .ant-table-tbody>tr>td>.ant-table-wrapper:only-child .ant-table {
  margin: 0;
}

.awb-rec {
  padding-left: 30px;
  padding-right: 20px;
}

.custom-table.buildup-table .ant-table {
  background-color: transparent;
  max-height: calc(100vh - 500px);
}

.bc-slider.slick-slider {
  width: calc(100% - 70px);
  margin-bottom: 0;
}

.bc-slider .slick-track {
  margin: 0;
}

.opacity {
  opacity: 0.5;
  background-color: green;
}

.bc-dd {
  min-width: 200px !important;
}

.ex-tabl .ant-table-row-expand-icon-cell,
.icon-col,
.ex-tabl .ant-table-expand-icon-col,
.ex-tabl .ant-table-thead>tr>th.ant-table-cell.ant-table-row-expand-icon-cell {
  width: 22px;
  min-width: 22px;
  max-width: 22px;
  padding-right: 0 !important;
}

th.ant-table-cell.fpe-col,
td.ant-table-cell.fpe-col {
  min-width: 45px !important;
}

.round-button.ant-popover-disabled-compatible-wrapper,
.round-button.ant-popover-disabled-compatible-wrapper:not(:disabled),
.round-button.ant-popover-disabled-compatible-wrapper:not(:disabled):hover,
.round-button.ant-popover-disabled-compatible-wrapper:not(:disabled):focus {
  background-color: transparent;
}

.sub-title {
  font-size: 15px;
  font-weight: 500;
  display: block;
}

.header-wrapper {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.header-wrapper span {
  font-size: 15px;
  font-weight: 600;
  display: block;
}

.header-wrapper span:not(:last-child) {
  margin-bottom: 4px;
  margin-right: 20px
}

.header-wrapper h3 {
  margin-bottom: 0;
}

.slider-transition .slick-track,
.slider-transition .slick-list {
  transform: translateX(0) !important;
  /* transform: translate3d(0px, 0px, 0px) !important; */
}

.next-button {
  right: 0px;
}

.prev-button {
  left: 0px;
}

.prev-button,
.next-button {
  opacity: 0;
  /* functionality is on this button */
}

.bc-slick-next {
  right: 12px;
}

.bc-slick-prev {
  left: 12px;
}

@media (min-width: 1600px) {
  .capsule {
    font-size: 12px;
  }

  .capsule>span {
    min-width: 34px;
  }

  .custom-table.buildup-table .ant-table {
    background-color: transparent;
    max-height: calc(100vh - 460px);
  }
}


@media (max-width: 1200px) {
  .repl-btn {
    width: 200px
  }
}

@media (max-width: 630px) {
  .bl-width {
    width: 100%;
  }
}

@media (max-width: 576px) {
  .bl-width {
    width: 100%;
  }
}

@media print {
  @page {
    size: landscape
  }

  table tr {
    page-break-inside: auto
  }


  .uld-print .ant-card-head,
  .uld-print .ant-select-single .ant-select-selector .ant-select-selection-placeholder,
  .rankby-label .ant-form-item-label {
    display: none;
  }

  .custom-table table {
    border-spacing: 0 15px !important;
    margin-top: 0;
  }

  .custom-table .ant-table-tbody>tr>td {
    height: 40px;
  }

  .ant-select {
    width: auto;
  }

  .on-print {
    margin-bottom: 30px;
    border-bottom: 1px solid rgba(221, 221, 221, 0.842);
    padding-bottom: 30px;
  }

  .ant-popover {
    display: none;
  }

  .hidden-print-table {
    display: block !important;
  }

  .uld-print.ant-card {
    background-color: transparent !important;
    border: none;
  }

  .buildup-table td,
  .buildup-table th,
  .uld-print .ant-form-item-label>label,
  .uld-print label,
  .buildup-table .ant-statistic-content-value,
  .capsule,
  .ant-tag.primary-tag,
  .uld-print .ant-select-selector,
  .ant-select-selector,
  .ant-select-selector .ant-select-selection-item {
    font-size: 13px !important;
  }

  .buildup-table td {
    border: transparent !important;
  }

  .uld-print .ant-card-body {
    padding: 0;
  }

  .custom-table.buildup-table .ant-table {
    max-height: none;
  }

  .custom-table.ant-table-wrapper {
    overflow: hidden !important;
  }

  .buildup-table .ant-table .anticon-filter {
    display: none;
  }

  .buildup-table .ant-table-tbody>tr>td>.ant-table-expanded-row-fixed>.ant-table-wrapper:only-child .ant-table-tbody>tr:last-child>td,
  .buildup-table .ant-table-tbody>tr>td>.ant-table-wrapper:only-child .ant-table-tbody>tr:last-child>td {
    border-bottom: 1px solid var(--tb-border);
  }

  .ant-tag.primary-tag,
  .uld-print .ant-select-selector {
    border-color: transparent !important;
  }

  .ant-select-arrow {
    display: none !important;
  }

  .capsule {
    border-color: transparent;
  }

  .capsule>span:first-child,
  .capsule>span:last-child {
    background-color: transparent;
    color: black;
    border-color: transparent;
    padding: 0;
    position: relative;
  }

  .capsule>span:last-child {
    font-weight: normal;
  }

  .uld-pos-print {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .uld-pos-print .ant-form-item {
    /* width: 50%; */
    display: inline-block;
    margin-bottom: 20px !important;
    padding-left: 24px;
    margin-bottom: 40px;
  }

  .uld-pos-print .ant-form-item:last-child {
    border-right: none;
  }

  .print-w25:last-child {
    border-right: none;
  }

  .print-w25 {
    width: 25%;
    display: inline-block;
    padding-left: 24px !important;
    margin-bottom: 20px;

  }

  .print-w50 {
    width: 50%;
    display: inline-block;
    padding-left: 24px !important;
    margin-bottom: 20px;

  }

  .rankby-label {
    padding-left: 24px !important;
  }

  .flight-no {
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 30px;
    border-bottom: 1px solid lightgrey;
    padding-bottom: 10px;
  }

}