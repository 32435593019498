.change-password {
  --logingrey: #aaaaaa;
  --loginborderradius: 8px;
  height: 100%;
}

.change-password {
  min-height: calc(100vh - var(--headerheight) - 73px) !important;
  display: flex;
  align-items: center;
}

.change-password .ant-form-item-label>label {
  min-width: 150px;
}

.change-password .ant-input-affix-wrapper,
.change-password .ant-input-affix-wrapper>input.ant-input {
  background-color: white;
  border: none;
  padding-left: 10px !important;
}

.change-password .ant-input-prefix img {
  width: 20px;
  opacity: 0.1;
}

.change-password .ant-input-affix-wrapper,
.login-form button {
  border-radius: var(--loginborderradius);
}

.change-password button.submit-btn {
  background-color: var(--primaryblue);
  color: var(--logingrey);
  border: px solid var(--logingrey);
  height: 36px;
}

.change-password button.submit-btn:hover,
.change-password button.submit-btn:focus {
  background-color: var(--logingrey);
  color: var(--primaryblue);
}

.ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
.ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover,
.ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
  background-color: var(--logingrey) !important;
  font-size: 0.8rem;
}

/* .forgot-link {
    color: var(--logingrey);
    font-size: 12px;
} */

.change-password .ant-input:-webkit-autofill,
.change-password .ant-input:-webkit-autofill:hover,
.change-password .ant-input:-webkit-autofill:focus,
.change-password .ant-input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  box-shadow: 0 0 0 30px white inset !important;
}


@media screen and (max-width:990px) {
  .change-password.ant-row {
    display: block;
  }
}