.login-container-outer {
  height: 100vh;
  background-color: var(--primaryblue);
}

.login-container-outer .ant-input::placeholder {
  color: #626262;
}

.login-container {
  --logingrey: #aaaaaa;
  --loginborderradius: 8px;
  height: 100%;
}

.login-content {
  display: flex !important;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 6%;
}

.login-logo {
  width: 100%;
  background-color: white;
  border-radius: 50%;
  padding: 6px;
  object-position: 1px 2px;
  /* outline: 2px solid var(--redText); */
  outline-offset: 4px;
}

.login-company-title.ant-typography {
  color: #d8d8d8;
  font-weight: 800;
  margin-bottom: 0;
  font-size: 3.26vw;
}

.login-company-subtitle.ant-typography {
  color: var(--secondaryblue);
  font-weight: 300;
  margin-top: 0 !important;
  font-size: clamp(12px, 1vw, 1vw);
}

.divider {
  border-bottom: 1px solid var(--logingrey);
}

.login-heading.ant-typography {
  color: var(--logingrey);
  margin-bottom: 2rem;
  font-size: clamp(24px, 2vw, 2vw);

}

.login-form-width {
  width: 70%;
  margin-right: auto !important;
  margin-left: auto !important;
}

.login-input.ant-input-affix-wrapper,
.ant-input-affix-wrapper>input.ant-input {
  background-color: var(--logingrey);
  border: none;
  padding-left: 10px !important;
}

.login-input .ant-input-prefix img {
  width: 20px;
}

.login-input.ant-input-affix-wrapper,
.login-form button {
  border-radius: var(--loginborderradius);
}

.login-form button.submit-btn {
  background-color: var(--primaryblue);
  color: var(--logingrey);
  border: 2px solid var(--logingrey);
  height: 36px;
}

.login-form button.submit-btn:hover,
.login-form button.submit-btn:focus {
  background-color: var(--logingrey);
  color: var(--primaryblue);
}

.ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
.ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover,
.ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
  background-color: var(--logingrey) !important;
  font-size: 0.8rem;
}

.forgot-link {
  color: var(--logingrey);
  font-size: 12px;
}

.bck-img {
  background-image: url('../../assets/images/login-bkg.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.login-input .ant-input:-webkit-autofill,
.login-input .ant-input:-webkit-autofill:hover,
.login-input .ant-input:-webkit-autofill:focus,
.login-input .ant-input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px var(--logingrey) inset !important;
  box-shadow: 0 0 0 30px var(--logingrey) inset !important;
}

.logo-wrap-gap {
  row-gap: 40px;
}

@media screen and (max-width:990px) {
  .login-company-title.ant-typography {
    font-size: 5.28vw;
  }

  .login-container {
    width: 70%;
    margin-right: auto !important;
    margin-left: auto !important;
  }
  
  .bck-img {
    display: none;
  }
}

@media screen and (max-width:768px) {
  .login-company-title.ant-typography {
    font-size: clamp(30px, 6.8vw, 6.8vw);
  }

  .login-container {
    width: 90%;
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .login-form-width{
    width: 100%;
  }
}