.send.ant-btn {
  font-size: 12px;
  font-weight: 600;
}

.custom-table.unpr-msg-table .ant-table-tbody>tr>td {
  vertical-align: top;
  padding: 8px;
}

.unpr-msg-table td > ul, .unpr-msg-table td > ul > li {
  margin-top: 0 !important;
  padding-top: 0 !important;
}

.dt-col > * {
  margin-top: 8px;
}

.dt-col > *:first-child, .dt-col > *:last-child {
  margin-bottom: 16px;
}

.dt-col:first-child > *:first-child {
  margin-top: 0;
}

.step-collapse.ant-collapse {
  background-color: transparent !important;
  border: none;
}

.step-collapse .ant-collapse-content {
  background-color: var(--cardbkgcolor);
  border: 2px solid white;
}

.step-collapse .ant-collapse-content>.ant-collapse-content-box {
  width: 90%;
  margin: auto;
}
.step-collapse .ant-collapse-header {
  align-items: center;
}

.step-collapse .ant-collapse-item.ant-collapse-item-active >.ant-collapse-header{
  background-color: #ababab47;
  border-radius: 15px 15px 0 0;
  border: 1px solid #80808017;
  border-bottom: 1px solid var(--redText);
  padding: 10px 16px;
}

.step-collapse .ant-collapse-content-box > div {
  margin-bottom: 10px;
}

.step-collapse .send.ant-btn {
  min-width: 150px;
  margin-right: 8px;
}

.tick-icon {
  width: 20px;
  margin-left: 10px;
  position: absolute;
  right: 20px;
  top: 12px;
}

.demo-nested-tbl .send.ant-btn {
  margin-right: 0;
}

.desc-title {
  color: grey;
  border-bottom: 1px solid red;
  display: inline-block;
  margin-bottom: 10px;
}

.red-progressbar.ant-progress-line{
  font-size: 0;
  position: absolute;
  left: 0;
  bottom: 0;
}

.red-progressbar .ant-progress-bg {
  background-color: #d9363e !important;
}

.progress-bar{
  position: -webkit-sticky; /* Safari & IE */
  position: sticky;
  top: 0;
  z-index: 2;
  background: #d7d7d7;
}

.est {
  font-weight: 400;
  text-align: right;
  margin-right: -12px;
}

.steps-head-btn {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: -18px;
}

.steps-head-label {
  margin-bottom: 2px;
}
