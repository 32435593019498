.landing-main {
  min-height: 100vh;
}

.top-block {
  background-color: var(--primaryblue);
  height: calc(100vh - 50vh);
  display: flex;
  padding: 3vw 5vw;
  overflow: hidden;
  position: relative;
}

.dash-head {
  margin-bottom: 2vw;
  margin-left: auto;
  display: inline-block;
  color: white;
  font-size: 5vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: right;
}

.dash-head p {
  margin-bottom: 0;
}

.landing-loc {
  font-size: 3.5vw;
  align-self: flex-end;
  line-height: normal;
  border-bottom: 0.3vw solid #d0223a;
}

.top-block .ant-image {
  align-self: flex-start;
  width: 16vw;
  margin-top: auto;
  margin-left: -4.7vw;
  margin-bottom: 2vw;
}

.head-icon.anticon {
  font-size: clamp(50px, 50vw, 55vw);
  opacity: 0.05;
  position: absolute;
  right: -18vw;
  top: -3vw;
}

.head-icon2 {
  font-size: clamp(40px, 24vw, 24vw);
  opacity: 0.04;
  position: absolute;
  right: 40%;
  top: -10vw;
}

.head-icon.anticon,
.head-icon2.anticon {
  align-self: flex-start;
  color: white;
  -webkit-animation: spin 30s linear infinite;
  -moz-animation: spin 30s linear infinite;
  animation: spin 30s linear infinite;
}


/* rotate 360deg animation */
@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

.inner-block {
  background-color: var(--cardbkgcolor);
  width: 90%;
  margin: auto;
  padding: 4vw 3vw;
  margin-top: -5vw;
  box-shadow: 4px 4px 0.5vw #c2c1c1;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 3vw;
  position: relative;
  border-top: 0.3vw solid var(--redText);
}

.dash-heading {
  font-size: 10vw;
  color: #ffffff;
  text-transform: uppercase;
  margin-top: -9vw;
  text-align: center;
  text-shadow: 4px 6px black;
  display: none;
}

.ant-btn.dash-tile {
  background-image: linear-gradient(to right, #c0c0c04f, white);
  color: var(--primaryblue);
  box-shadow: 1px 1px 1vw lightgrey;
  border: 1px solid whitesmoke;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 47%;
  height: auto;
  border-radius: 1.5vw;
  font-size: clamp(18px, 1.8vw, 2vw);
  line-height: normal;
  font-weight: 500;
  padding: 2vw;
  position: relative;
}

.ant-btn.dash-tile:hover,
.ant-btn.dash-tile:focus {
  color: var(--redText);
  cursor: pointer;
  box-shadow: 1px 1px 1vw #0a122781;
  border: 0;
}

.dash-tile>span {
  text-align: left;
}

.dash-tile-icon {
  width: 4vw;
  height: auto;
  filter: invert(18%) sepia(58%) saturate(4733%) hue-rotate(340deg) brightness(89%) contrast(91%);
  opacity: 1;
}