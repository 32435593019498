
.header-search.ant-input-group-wrapper {
  position: absolute;
  top: 50px;
  left: 0;
  right: 0;
  margin: auto;
  width: clamp(240px, 30%, 350px);
}

.header-picker {
  width: 200px;
  position: absolute !important;
  top: 50px;
  left: 50%;
  transform: translateX(-50%);
}

@media screen and (max-width:990px) {
  .header-search.ant-input-group-wrapper {
    top: 50px;
    left: auto;
    right: 28px;
  }

  .header-picker {
    width: 200px;
    left: auto;
    right: -50px;
  }
}