.buildup-dash .ant-table-thead>tr>th,
.buildup-dash .warehouse-table .ant-table-tbody>tr>td {
  --bdfs: 1.5vw;
  padding: 0.8vw 0.8vw;
  font-size: var(--bdfs);
  white-space: nowrap;
  text-align: left;
}

.buildup-dash.warehouse-screen .ant-statistic-content {
  color: var(--tb-border);
}

.buildup-dash.warehouse-screen .ant-statistic-content-value {
  font-size: var(--bdfs);
}

.buildup-dash .ant-picker-input>input {
  font-size: 2vw;
}

.ant-drawer-title {
  font-size: 2vw !important;
  line-height: normal !important;
}

.ant-drawer-header {
  background-color: #efefef !important;
}

.buildup-dash.warehouse-screen .ant-input-number {
  width: 100% !IMPORTANT;
}

.warehouse-screen .ant-checkbox {
  transform: scale(2.2);
  margin-right: 14px;
  margin-left: 12px;
}

.builup-x {
  position: absolute !important;
  top: 0;
  right: 0;
}

.dash-checkbox .ant-form-item-label {
  margin-bottom: 1vw !important;
}

.warehouse-screen .ant-input-number.ant-input-number-sm.ant-input-number-in-form-item.w-100 {
  width: 100% !important;
}

.ant-form-item.transparent label {
  color: transparent !important;
}

.dr-buildup .ant-picker-input>input::placeholder,
.dr-buildup .ant-picker-input>input {
  font-size: 1.4vw !important;
}

/* .dr-buildup .ant-drawer-content-wrapper {
  min-height: 25vw;
  height: 25vw !important;
} */
.red-bu {
  /* background: var(--danger) !important; */
  color: var(--danger) !important;
  border-color: transparent !important;
}

.amber-bu-bg td {
  color: var(--warning) !important;
  border-color: transparent !important;
  border-bottom: 0.2vw solid #464646 !important;
}

.brown-bu-bg td {
  color: var(--brown) !important;
  border-color: transparent !important;
  border-bottom: 0.2vw solid #464646 !important;
}

.red-bu-bg td {
  color: var(--danger) !important;
  border-color: transparent !important;
  border-bottom: 0.2vw solid #464646 !important;
}

.sel-flt-rw .ant-col {
  margin-bottom: 1vw;
}

.bd-btns {
  margin-top: auto;
  margin-bottom: 1.5vw;
}

/* checkbox for tv screens */
@media screen and (min-width: 3000px) {
  .warehouse-screen.tv-res .ant-checkbox {
    transform: scale(6);
  }

  /* .warehouse-screen.tv-res .ant-checkbox + span {
    margin-left: 1vw;
    margin-right: 1vw;
  } */
  .tv-res .ant-checkbox+span {
    margin-left: 1vw;
    margin-right: 1vw;
  }

  .dash-checkbox .ant-form-item-label {
    margin-bottom: 0.5vw !important;
  }

  .warehouse-screen.tv-res .ant-checkbox-wrapper {
    margin-left: 1vw;
    margin-right: 1vw;
  }

  .warehouse-screen .tv-res .ant-switch {
    transform: scale(2);
    margin-left: 2vw;
  }

  .warehouse-drawer.tv-res .ant-checkbox {
    transform: scale(6);
  }

  .tv-res.ant-picker-dropdown {
    transform: scale(3);
  }

  .tv-res-s2 {
    transform: scale(2);
  }

  .tv-mr-2vw {
    margin-right: 2vw;
  }

  .tv-ml-2vw {
    margin-left: 2vw;
  }
}