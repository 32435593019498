/* ========== common tabs =========== */
/* ========== fleet =========== */
/* ========== handling Times =========== */
/* ========== product code & priority =========== */
/* ========== rebooking teaams =========== */
/* ========== recovery actions =========== */
/* ========== product mapping =========== */
/* ========== dynamic info =========== */
/* ========== buildup area mapping =========== */
/* ========== haulout area mapping =========== */
/* ========== media query =========== */

.image-btn.clear-btn.fleet-btn-placement:not(:disabled),
.image-btn.clear-btn.product-btn-placement:not(:disabled),
.image-btn.clear-btn.recovery-btn-placement:not(:disabled),
.image-btn.clear-btn.rebooking-btn-placement:not(:disabled),
.image-btn.clear-btn.dynamic-btn-placement:not(:disabled),
.image-btn.clear-btn.productmapping-btn-placement:not(:disabled) {
  position: absolute;
  top: calc(-3.5 * var(--bodymargin));
  right: calc(1 * var(--bodymargin));
}

.image-btn.clear-btn.handling-btn-placement:not(:disabled) {
  position: absolute;
  top: calc(-2 * var(--bodymargin));
  right: calc(1 * var(--bodymargin));
}

.fleet .ant-checkbox-wrapper+.ant-checkbox-wrapper {
  margin-left: 0px;
}

.form-width {
  width: 70% !important;
  margin-right: auto !important;
  margin-left: auto !important;
}

.form-clear {
  font-size: 19px;
  font-weight: 700;
  color: var(--redText);
  text-transform: lowercase;
  line-height: 0;
}

.radio-nowrap {
  white-space: nowrap;
}

/* ========== fleet =========== */

.scaletbl-img {
  transform: scale(0.8);
}

.fleet-tableupload .ant-upload-list-item-name {
  white-space: normal !important;
  line-height: 1.2 !important;
}

.fleet-tableupload .ant-upload-list-item {
  min-height: 24px;
  height: auto !important;
}

.preview-modal .ant-modal-body {
  padding: 48px;
}

.fleet-table .ant-table {
  max-height: calc(100vh - 503px);
}

/* ========== handling Times =========== */
.grey-title {
  font-size: 12px;
  color: #939393;
}

.handling-times-ip {
  font-size: 13px;
  margin-top: 0px;
}

.w-50px.ant-input-number {
  width: 50px !important;
}

.handling-times-ip .green.ant-input-number {
  border: 1px solid var(--success) !important;
}

.handling-times-ip .red.ant-input-number {
  border: 1px solid var(--danger) !important;
}

.custom-table.ht-table .ant-table {
  max-height: 170px
}

/* .ant-input-number-input {
  padding: 0 5px;
} */

/* ========== product code & priority =========== */
.pc-table .ant-table {
  max-height: calc(100vh - 486px);
}

/* ========== rebooking teaams =========== */
.rebooking-table .ant-table {
  max-height: calc(100vh - 486px);
}

/* ========== recovery actions =========== */
.rec-act-table .ant-table {
  /* max-height: calc( 100vh - 566px ); */
  max-height: calc(100vh - 490px);
}

/* ========== product mapping =========== */
.workarea-table .ant-table,
.productlist-table .ant-table,
.location-table .ant-table {
  max-height: calc(100vh - 409px);
}

/* header search */
.colheader-search {
  --headersearch: 22px;
}

th.table-header-search {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.table-header-search .ant-table-filter-column {
  align-items: center;
}

.table-header-search .ant-table-filter-trigger {
  margin: 0;
  margin-right: 10px;
  width: 50%;
  background-color: transparent !important;
}

.colheader-search.ant-input-search .ant-input-affix-wrapper {
  min-height: var(--headersearch) !important;
  background-color: white;
}

.colheader-search .ant-input-wrapper.ant-input-group {
  margin-top: -2px;
}

.colheader-search .ant-input {
  min-height: calc(var(--headersearch) - 4px) !important;

}

.colheader-search.ant-input-search .ant-input-affix-wrapper input.ant-input {
  height: calc(var(--headersearch) - 2px) !important;
}

.colheader-search.ant-input-search .ant-input-affix-wrapper {
  border-radius: 20px !important;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  border-width: 1px !important;
}

.colheader-search span.ant-input-group-addon,
.colheader-search .ant-input-search-button {
  border-radius: 50% !important;
  height: calc(var(--headersearch) - 4px) !important;
  width: calc(var(--headersearch) - 2px) !important;
  background-color: transparent;
  border: none !important;
  box-shadow: none !important;
  z-index: 1;
  top: 0px;
}

.colheader-search .ant-input-search-button:hover,
.colheader-search .ant-input-search-button:focus {
  background-color: transparent;
}

.colheader-search .ant-input-suffix,
.search-field .ant-input-suffix {
  margin-right: calc(var(--headersearch) + 6px) !important;
}

.colheader-search.ant-input-search .ant-input-affix-wrapper:focus,
.colheader-search.ant-input-search .ant-input-affix-wrapper-focused {
  background-color: white;
}

/* ========== dynamic info =========== */
.dynamic-table .ant-table {
  max-height: calc(100vh - 487px);
}

.dynamic-upload .ant-upload-list-picture .ant-upload-list-item-name,
.dynamic-upload .ant-upload-list-text .ant-upload-list-item-name {
  max-width: 60px;
  padding: 0;
}

.dynamic-upload .ant-upload-list-item {
  min-height: 24px;
  height: auto !important;
}

.dynamic-upload .ant-upload-list-item-name {
  white-space: normal !important;
  line-height: 1.2 !important;
  font-size: 12px;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.dynamic-upload .ant-upload-text-icon {
  display: none;
}

.dynamic-upload .ant-form-item-label {
  text-align: center !important;
}

.pfd-upload a,
.pfd-upload a:hover,
.pfd-upload a:focus {
  color: inherit;
}

/* xx */
.wordbreak {
  word-break: break-all;
}

/* scroll */
/* :root {
  --scrollbar-height: 2px;
  --scrollbar-padding: 35px;
}


::-webkit-scrollbar {
  height: calc(var(--scrollbar-padding) + var(--scrollbar-height));
	background-color: rgba(255,255,255,0);
}

::-webkit-scrollbar-track,
::-webkit-scrollbar-thumb {
	border: 16px solid rgba(255,255,255,0);
	background-clip: padding-box;
}

::-webkit-scrollbar-track {
	background-color: #ccc;
  border: 16px solid rgba(255,255,255,0);

}

::-webkit-scrollbar-thumb {
	background-color: #212121;
  border: 2px solid rgba(255,255,255,0);

}
::-webkit-scrollbar-thumb:hover {
	border: var(--scrollbar-padding) solid rgba(255,255,255,0);
} */

.scrollsync-outer {
  display: flex;
  position: relative;
}

.scrollsynctable-1 {
  overflow: auto;
  width: 70%;
}

.scrollsynctable-2 {
  overflow: auto;
  width: 30%;
  margin-left: 24px;
}

/* .with-table>[class*="col-"].col-pr-0 {
  padding-right: 0px !important;
} */

.table-pr-0 table {
  padding-left: 0 !important;
}

.card-body-pt-0 .ant-card-body {
  padding-top: 0 !important;
}

.min-63vh {
  min-height: 63vh;
}

.min-45vh {
  min-height: 45vh;
}

/* ========== uld mapping =========== */

.checkbox-item-30 .ant-checkbox-group-item {
  width: 30%;
}

.uldmapping-table .ant-table {
  max-height: calc(100vh - 450px);
}

.ac-type-wrapper .ant-checkbox-wrapper {
  padding-right: 1vw;
  padding-bottom: 1vw;
}

.ac-type-wrapper .ant-checkbox-disabled+span {
  color: inherit;
}

.compt-card {
  border-radius: 9px !important;
}

.compt-card .ant-card-head {
  border: none;
}

.compt-card .ant-card-head-title {
  padding-bottom: 12px;
  border-bottom: 1px solid var(--tb-border);
  font-weight: 500;
  overflow: auto;
  white-space: inherit;
}

.type-rd-wrap .ant-radio-wrapper {
  margin-right: 0;
}

/* ========== buildup area mapping =========== */
.bu-table .ant-table {
  max-height: calc(100vh - 588px);
}

/* ========== haulout area mapping =========== */
.haul-table .ant-table {
  max-height: calc(100vh - 396px);
}

/* ========== media query =========== */

@media screen and (min-width:1600px) {
  .mr-neg-12 {
    margin-right: -12px;
  }

  .product-ht .ant-col-xxl-2 {
    display: block;
    flex: 0 0 8%;
  }

  .custom-table.ht-table .ant-table {
    max-height: calc(50vh - 260px);
  }

  .uldmapping-table .ant-table {
    max-height: calc(100vh - 355px);
  }
}

@media screen and (max-width:1638px) {
  .rec-act-table .ant-table {
    /* max-height: calc( 100vh - 566px ); */
    max-height: calc(100vh - 540px);
  }
}

@media screen and (max-width:1599px) {
  .rec-act-table .ant-table {
    /* max-height: calc( 100vh - 566px ); */
    max-height: calc(100vh - 570px);
  }
}

@media screen and (max-width:1199px) {
  .rec-act-table .ant-table {
    /* max-height: calc( 100vh - 566px ); */
    max-height: calc(100vh - 620px);
  }
}

@media screen and (max-width:1402px) {
  .fleet-table .ant-table {
    max-height: calc(100vh - 555px);
  }
}

@media screen and (max-width:1259px) {
  .fleet-table .ant-table {
    max-height: calc(100vh - 580px);
  }
}

@media screen and (max-width:990px) {
  .pc-table .ant-table {
    max-height: calc(100vh - 562px);
  }
}

@media screen and (max-width:1107px) {
  .fleet-table .ant-table {
    max-height: calc(100vh - 631px);
  }
}

@media screen and (max-width:1400px) and (min-width:992px) {
  /* .with-table>[class*="col-"].col-pr-0 {
    padding-right: 0px !important;
  } */

}

@media screen and (max-width:991px) {
  .scrollsync-outer {
    flex-wrap: wrap;
  }

  .scrollsynctable-1 {
    width: 100%;
  }

  .scrollsynctable-2 {
    width: 100%;
    margin-top: 24px;
    margin-left: 0;
  }
}

@media screen and (max-width:576px) {
  .form-width {
    width: 100% !important;
  }
}