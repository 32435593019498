.json-card.ant-card {
  padding: 10px 20px;
  border-radius: 20px;
  width: 33.33%;
}

.json-card .ant-card-body {
  padding: 0 !important
}

.json-card ul {
  font-size: 12px;
}

.json-card-container {
  display: flex !important;
  gap: 20px;
}

.json-card h3 {
  margin-bottom: 0;
}

.header-messages .header-search {
  position: static;
  width: auto;
}

.mess-no {
  font-size: 16px;
  color: var(--greyText);
  text-align: right;
  padding-right: 18px;
  margin-top: 18px;
}

.p-msgtbl {
  height: calc(var(--content-height) - 218px);
}


ul.msg-menu.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-dark.slider-menu {
  height: 100%;
  border-top-left-radius: 14px;
  border-bottom-left-radius: 14px;
  padding-top: 50px;
}

.msg-menu li.sider-menu-item.ant-menu-item.ant-menu-item-only-child {
  min-height: 46px !important;
  text-align: left;
  font-size: 12px;
}


.h-msg-menu .ant-menu-title-content {
  font-weight: 500;
}

.h-msg-menu.ant-menu-horizontal {
  background-color: transparent !important;
  font-size: 12px !important;
  /* line-height: 32px !important; */
  margin-bottom: 18px !important;
  border-color: transparent;
}

.h-msg-menu.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-active,
.h-msg-menu.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-open,
.h-msg-menu.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-selected,
.h-msg-menu.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item:hover,
.h-msg-menu.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-active,
.h-msg-menu.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-open,
.h-msg-menu.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-selected,
.h-msg-menu.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu:hover {
  color: var(--redText) !important;
}

.h-msg-menu.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-active:after,
.h-msg-menu.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-open:after,
.h-msg-menu.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-selected:after,
.h-msg-menu.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item:hover:after,
.h-msg-menu.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-active:after,
.h-msg-menu.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-open:after,
.h-msg-menu.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-selected:after,
.h-msg-menu.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu:hover:after {
  border-bottom: 2px solid var(--redText) !important;
}


.msg-timeline .ant-timeline-item {
  font-size: 12px !important;
  color: grey !important;
}

.msg-timeline .ant-timeline-item:last-child {
  padding-bottom: 0 !important;
}

.mess-card.custom-card .ant-card-body {
  padding: 0 !important;
}

.link-btn.ant-btn-link {
  color: var(--primaryblue) !important;
  font-weight: 500 !important;
  font-size: 12px !important;
}