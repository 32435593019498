.queued-messages {
    font-size: 22px;
    padding-left: 10px;
    font-weight: 600;
    color: var(--redText);
}

/* JSON tree */
.json-tree ul {
    margin-top: 0;
}

tr:hover .json-tree-td ul {
    background-color: transparent !important;
    transition: 0s;
}