.fc-table .ant-table {
  max-height: calc(var(--content-height) - 162px);
}

.config-img img {
  width: 100%;
  height: auto;
}

.fcst-col>.ant-space {
  width: 80%;
}

.fcst-col>.ant-space>.ant-space-item:first-child {
  width: 24px !important;
}

.data-row .ant-input-number-sm {
  width: 30px !important;
}

.cargo-summary {
  background-color: var(--primaryblue);
  color: white;
  border-radius: 10px;
  height: 150px;
  overflow-y: auto;
}

.cargo-summary:before {
  content: '';
  position: absolute;
  background-color: var(--primaryblue);
  width: 80%;
  bottom: 0px;
  height: 10px;
  left: 13px;
}

.cargo-summary p {
  padding: 0 10px;
  font-size: 11px;
  margin-bottom: 0.5rem !important;
}

.cargo-summary p:first-child {
  padding: 10px 10px;
  border-bottom: 1px solid var(--redText);
  position: sticky;
  top: 0;
  background-color: var(--primaryblue);
}

.cargo-summary p span:nth-child(2) {
  float: right;
}

.dib-w90 {
  width: 90px;
  display: inline-block;
}

.rem_posn,
.rem-pos-title {
  font-size: 12px;
  font-weight: 500;
}

.load-card .info-title:not(.sub-head .info-title) {
  min-height: 47px;
  display: inline-block;
}

.cc-load .ant-input-number-handler-wrap,
.rem_posn .ant-input-number-handler-wrap {
  display: none;
}

.cc-load .ant-input-number-sm input {
  padding: 0 5px;
}

.custom-table.multi-head.fc-table .psn {
  min-width: 27px;
}

.dis-blue.ant-input-number-disabled {
  background-color: var(--primaryblue) !important;
  color: white
}

.dis-grey.ant-input-number-disabled {
  background-color: #b1b1b1 !important;
  color: white;
}

.dis-red.ant-input-number-disabled {
  background-color: var(--redText) !important;
  color: white;
}

.config-placeholder {
  min-height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 3px dotted lightgray;
  font-size: 13px;
}

.info-card-2.ant-card {
  border-color: var(--primaryblue);
  border-radius: 10px;
  background-color: var(--primaryblue);
  color: #fff;
}

.info-card-2 .ant-divider {
  border-color: var(--success);
  border-width: 3px;
}

.clip.ant-btn {
  width: 15px;
  height: 20px;
  min-width: 10px;
}

.clip .anticon {
  transform: rotate(317deg);
}

.table-pr-0 table {
  padding-right: 0;
}

.place-end {
  place-content: end;
  display: grid !important;
}

.light-checkbox .ant-checkbox-checked .ant-checkbox-inner:after {
  border-color: var(--primaryblue);
}

.light-checkbox .ant-checkbox-checked:not(.ant-checkbox-disabled.ant-checkbox-checked) .ant-checkbox-inner {
  background-color: #fff !important;
}

.disabled-bg-none.ant-btn.ant-btn-background-ghost[disabled] {
  background-color: transparent !important;
  border-color: transparent !important;
  color: #fff;
}

.load-plan .info-card.cc-card.ant-card .info-title {
  font-size: 14px;
  color: #AAAAAA;
  font-family: 'ArialMT', 'Arial', sans-serif;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
}

.load-plan .info-card.cc-card.ant-card .info-data {
  font-size: 17px;
  color: #FFFFFF;
  font-family: 'ArialMT', 'Arial', sans-serif;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
}

.load-plan .info-card.ant-card {
  background-color: rgba(9, 43, 74, 1);
}

.w-40 {
  width: 40px !important;
}

.load-plan .ant-input-number-sm {
  min-height: 25px !important;
  height: 25px;
}

.bottom-0 {
  position: absolute;
  bottom: 0;
}

.load-plan .ant-modal-body {
  overflow: auto;
  height: 80vh;
}

.load-plan .ant-modal-header {
  padding-bottom: 1rem !important;
  padding-top: 1rem !important;
}

.load-plan .ant-modal-footer {
  background-color: #f2f2f2;
}

.border-dark {
  border: 2px solid #000;
}

.curved-card .ant-card-head {
  border-radius: var(--modalborderradius);
  border-bottom: none;
}

.curved-card {
  border-radius: var(--modalborderradius) !important;
}

.curved-card .ant-card-head {
  border-radius: var(--modalborderradius) var(--modalborderradius) 0 0 !important;
}

.curved-card .ant-card-body {
  padding: 0.5rem !important;
}

.custom-padding .ant-card-head-title {
  padding-bottom: 0;
}

.custom-padding .ant-card-body {
  padding-top: .25rem !important;
}

.cargo-cap-card .flight-ctrl .info-title {
  padding-bottom: 0;
}

.recd-ht {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding-bottom: 3px;
}

.conf-img {
  max-height: 148px;
  width: auto;
}

.load-plan .ant-input-number-disabled {
  color: rgb(0 0 0 / 55%) !important;
  background-color: #d1d1d1 !important;
}

@media screen and (max-width: 1200px) {
  .load-card>.ant-col:nth-last-child(-n+4) {
    border-top: 1px solid #d3d3d338;
    padding-top: 12px;
  }
}

@media screen and (max-width: 768px) {
  .load-card>.ant-col:nth-last-child(-n+5) {
    border-top: 1px solid #d3d3d338;
    padding-top: 12px;
  }
}

@media screen and (max-width: 570px) {
  .load-card>.ant-col {
    border-top: 1px solid #d3d3d338;
    padding-top: 24px;
  }

  .info-title:not(.sub-head .info-title) {
    min-height: auto;
  }
}

@media (min-width: 1600px) {
  .load-plan .info-card.cc-card.ant-card .info-title {
    font-size: 18px;
  }

  .load-plan .info-card.cc-card.ant-card .info-data {
    font-size: 22px;
  }

  .load-card .info-title:not(.sub-head .info-title) {
    min-height: 57px;
  }
}