/* .ant-progress-success-bg,
.ant-progress-bg,
.ant-progress-inner {
  border-radius: 0 !important;
} */

.inbound-flights {
  --content-ht-full: calc(100vh - var(--headerheight) - 73px) !important;
  /* calc( total height - header height - content margin (top plus bottom) )  */
  --content-ht-half: calc(var(--content-ht-full) / 2 - 12px) !important;
  --content-tb-ht-half: calc(var(--content-ht-half) - 80px) !important;
  --content-tb-ht-full: calc(var(--content-ht-full) - 80px) !important;
}

/* .overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
} */

.custom-modallike-outer {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 100;
}

.custom-modallike {
  --modalborderradius: 10px;
}

.custom-modallike {
  background-color: white;
  border-radius: var(--modalborderradius) !important;
}

.custom-modallike-body {
  padding: 24px 12px !important;
  max-height: 420px;
  overflow: auto;
}

.ant-modal-content {
  border-radius: var(--modalborderradius) !important;
}

.custom-modallike-head {
  background-color: var(--primaryblue) !important;
  border-radius: var(--modalborderradius) var(--modalborderradius) 0 0 !important;
  color: white !important;
  padding: 12px 24px;

}

.custom-modallike .ant-typography,
.custom-modallike .anticon-close {
  color: white !important;
}

.custom-modallike .ant-typography {
  margin-bottom: 0;
}

.custom-modallike .custom-modallike-head .ant-btn {
  color: white !important;
  margin-bottom: 0;
  background-color: transparent;
  border: none;
  box-shadow: none;
}

.flight-notes-modal {
  width: 280px;
}

.add-flight-modal {
  width: 280px;
}

/* .inbound-label .ant-form-item-label>label {
  padding-left: 12px !important;
} */

.p-12[class*="col-"].green-flights {
  padding-left: 24px !important;
}

.label-outer {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-top: 0;
}

.pointer {
  cursor: pointer;
}

.countdown .ant-statistic-content-value {
  font-size: var(--tblfs);
}

.countdown .ant-statistic-content {
  display: contents;
}

.red.countdown .ant-statistic-content-value {
  color: red;
}

.green.countdown .ant-statistic-content-value {
  color: green;
}

.amber.countdown .ant-statistic-content-value {
  color: #eaaa00;
}

.black.countdown .ant-statistic-content-value {
  color: inherit;
}

.selected td .black.countdown .ant-statistic-content-value {
  color: inherit;
}

.status-popover .ant-popover-title {
  border-bottom: 1px solid #bfbfbf;
  font-size: 16px;
  margin: 0 5px
}

.status-popover .ant-popover-inner-content {
  max-height: 250px;
  overflow: auto;
  font-size: 1rem;
}

.status-popover .ant-popover-inner-content p:last-child {
  margin-bottom: 0;
}

.arriving-flight-table.custom-table .ant-table {
  max-height: calc(var(--content-ht-full) - 140px);
}

.hidden-countdown {
  display: none;
}

.splice-countdown td {
  background-color: #d7d7d7 !important;
}

.outer-info {
  background-color: #e4e4e4;
  border-radius: 9px;
  font-size: 1rem;
  padding: 18px 24px;
  position: relative;
}

.dynamicinfo-modal {
  min-width: 60%;
}

.inbound-action-col {
  text-align: right !important;
}

.notes-badge sup.ant-scroll-number.ant-badge-count.ant-badge-count-sm {
  background-color: var(--primaryblue);
}

.selected .notes-badge sup.ant-scroll-number.ant-badge-count.ant-badge-count-sm {
  background-color: white;
  color: var(--primaryblue);
}

.selected .notes-badge sup.ant-scroll-number.ant-badge-count.ant-badge-count-sm span.ant-scroll-number-only-unit.current {
  color: var(--primaryblue);
  /* font-size: 8px; */
}

.ib-tbl-dd {
  width: 200px !important;
}

@media screen and (max-width: 1600px) and (min-width: 1200px) {
  .arriving-flight-table.custom-table .ant-table {
    max-height: calc(var(--content-ht-full) - 170px);
  }

}

@media screen and (min-width: 992px) and (max-width: 1200px) {
  .arriving-flight-table.custom-table .ant-table {
    max-height: calc(var(--content-ht-full) - 230px);
  }
}

@media screen and (min-width: 991px) {

  .content-ht-full {
    min-height: var(--content-ht-full);
  }

  .content-tb-ht-full {
    min-height: calc(var(--content-tb-ht-full) - 80px);
  }

  .content-ht-half {
    height: var(--content-ht-half);
  }

  .content-tb-ht-half {
    height: var(--content-tb-ht-half);
  }

}