/* ==== CLEAR DATA ==== */
/* ==== UNPROCESSED MESSAGES ==== */


/* ==== CLEAR DATA ==== */
h5.ant-typography{
    margin-bottom: 0 !important;
    line-height: 1 !important;
    line-height: 1 !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    margin-bottom: 12px !important;
}

.grid-wrapper {
    width: clamp(80%, 1000px, 100%);
    margin: auto;
    display: flex;
    flex-wrap: wrap;
}

.clear-wrapper.ant-col {
    display: flex;
    align-items: flex-start;
    column-gap: 20px;
    padding: 40px 0 40px 40px;
}

.dd-icon {
    color: white;
    padding: 10px;
    background-color: var(--primaryblue);
    border-radius: 50%;
}

.dd-icon > svg {
    color: white;
}

.dd-wrapper {
    border-right: 1px solid #5252521c;
    padding-right: 40px;
}

@media (max-width: 767px) {
    .dd-wrapper {
        border-right: none;
    }

    .clear-wrapper.ant-col, .dd-wrapper {
        padding-right: 0;
        padding-left: 0;
    }
}


/* ==== UNPROCESSED MESSAGES ==== */
.unpr-msg-table ul {
    background-color: transparent !important;
}

.custom-table.unpr-msg-table .ant-table {
    max-height: calc(100vh - 254px);
}