.custom-button-left.ant-btn {
  margin-left: auto;
  display: block;
  margin-top: 24px;
}

@media screen and (min-width: 1599px) {
  .manage-table .ant-table {
    max-height: calc(var(--content-height) - 280px);
  }
}


@media screen and (max-width: 1600px) and (min-width: 993px) {
  .manage-table .ant-table {
    max-height: calc(var(--content-height) - 360px);
  }
}
