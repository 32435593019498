.notitle-modal {
  --modalborderradius: 10px;
}

.notitle-modal .ant-modal-body {
  border-radius: var(--modalborderradius) !important;
  padding: 30px !important;
}

.notitle-modal .custom-button:not(:disabled) {
  min-width: 90px;
}

.rebooking-select.ant-select {
  width: 20%;
  margin: auto;
  margin-bottom: 24px;
  display: block;
}

.rebooking-select.ant-select:hover,
.rebooking-select.ant-select:focus,
.rebooking-select.ant-select.ant-select-focused,
.ant-select-open {
  border: inherit;
}

.rebooking-select.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: var(--cardbkgcolor);
}

.rebk-table .ant-table {
  max-height: calc(var(--content-height) - 210px);
}

@media screen and (max-width: 992px) {
  .rebk-table .ant-table {
    max-height: calc(var(--content-height) - 280px);
  }
}

@media screen and (max-width: 767px) {
  .rebooking-select.ant-select {
    width: 50%;
  }
}