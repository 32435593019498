.tabs {
  width: calc(100% - 4px) !important;
  background-color: var(--secondarygrey);
  color: var(--primaryblue);
  border-radius: 10px 10px 0 0;
  padding: 0 8px;
  min-height: 38px;
  line-height: 1.1;
  display: flex !important;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  text-align: center;
  cursor: pointer;
}

.active-tab {
  background-color: var(--primaryblue);
  color: white;
  border-bottom: 3px solid var(--redText);
}

.tabs:hover,
.tabs:focus,
.tabs:active {
  background-color: var(--primaryblue);
  color: white;
  border-bottom: 3px solid var(--redText);
}

.custom-slider.slick-slider {
  width: 100%;
  /* without arrows */
  margin: auto; /* CHECK FOR ULD MAPPING SCREEN */
}

.hidden-table-icon {
  visibility: hidden;
  width: 13px;
}

.red-border {
  color: red;
  font-weight: 900;
}

.ant-select-selection-placeholder {
  font-weight: 400;
}

@media screen and (min-width: 768px) {
  .sys-para-slider.slick-slider {
    width: calc(100% - 50px);
    /* with arrows */
  }
}

@media screen and (max-width: 767px) {
  .custom-slider.slick-slider {
    width: calc(100% - 4*var(--bodymargin));
    margin: auto;
  }
}